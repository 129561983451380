<template>
  <div>
    <v-card class="mb-4">
      <v-card-title>
        {{ $t("Settings") }}
      </v-card-title>
      <v-card-text>
        <v-switch v-model="page.Enabled" :label="$t('Enabled')" light class="">
        </v-switch>

        <v-switch
          v-model="page.EnableApi"
          :label="$t('Enable API')"
          light
          class=""
        >
        </v-switch>

        <v-switch v-model="page.Custom" :label="$t('Custom')" light class="">
        </v-switch>

        <v-switch
          v-model="page.Group"
          :label="$t('Is grouppage')"
          light
          class=""
        >
        </v-switch>

        <v-switch
          v-model="page.RepeatingContent"
          :label="$t('Repeat content')"
          light
          class=""
        >
        </v-switch>

        <v-switch
          v-model="page.CacheContent"
          :label="$t('Cache content')"
          light
          class=""
        >
        </v-switch>

        <v-switch
          v-model="page.EnableOnSitemap"
          :label="$t('Enable on sitemap')"
          light
          class=""
        >
        </v-switch>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: [Array, Object],
    },
  },
};
</script>

<style>
</style>